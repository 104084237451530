var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { height: "100%" }, attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        {
          staticStyle: { height: "100%" },
          attrs: { "no-gutters": "", justify: "center" }
        },
        [
          _c(
            "v-col",
            {
              staticClass: "text-h5 text-center",
              attrs: { cols: "12", "align-self": "start" }
            },
            [_vm._v(" " + _vm._s(_vm.$t("account.payment.title")) + " ")]
          ),
          _c(
            "v-col",
            {
              staticClass:
                "text-subtitle-1 vitrueGrey--text font-weight-medium data-hj-suppress",
              attrs: { cols: "12", lg: "8", "align-self": "center" }
            },
            [
              _c("p", {
                staticClass: "mb-12",
                staticStyle: { "font-size": "20px" },
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("account.payment.totalPriceDescription", {
                      "0": _vm.plan.totalCalculatedPrice.toFixed(2)
                    })
                  )
                }
              }),
              _c("v-stripe-card", {
                ref: "stripeElement",
                attrs: {
                  label: _vm.$t("account.payment.label"),
                  hint: _vm.$t("account.payment.hint"),
                  hidePostalCode: true,
                  outlined: "",
                  "api-key": _vm.stripePublicKey
                },
                model: {
                  value: _vm.cardSource,
                  callback: function($$v) {
                    _vm.cardSource = $$v
                  },
                  expression: "cardSource"
                }
              })
            ],
            1
          ),
          _vm.errorMessage
            ? _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12", lg: "8" } },
                [
                  _c("alert-message", {
                    attrs: { message: _vm.errorMessage, type: "error" }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", "align-self": "center" } },
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "10", lg: "5" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-h5",
                          attrs: {
                            rounded: "",
                            color: "secondary",
                            width: "100%",
                            loading: _vm.paymentInProgress
                          },
                          on: { click: _vm.createPaymentMethodAndCustomer }
                        },
                        [_vm._v(_vm._s(_vm.$t("buttons.complete")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }