var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: _vm.dialogSize },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "px-4" },
        [
          _c(
            "v-btn",
            {
              staticClass: "closeButton",
              attrs: { icon: "", "x-large": "", color: "black" },
              on: {
                click: function($event) {
                  return _vm.$emit("input", false)
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
          _vm.currentStep !== 0
            ? _c(
                "div",
                { staticClass: "backButton text-body-1" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", "x-large": "", color: "black" },
                          on: {
                            click: function($event) {
                              _vm.currentStep -= 1
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                        1
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.currentStep == 1
                              ? _vm.$t(
                                  "account.subscription.dialog.checkPricePlan"
                                )
                              : ""
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            {
              staticClass: "py-6",
              staticStyle: { "min-height": "600px" },
              attrs: { justify: "center", "no-gutters": "" }
            },
            [
              _vm.currentStep === 0
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("subscription-menu", {
                        attrs: {
                          stripeSubscriptionPlans: _vm.stripeSubscriptionPlans,
                          onOldPricePlan: _vm.onOldPricePlan
                        },
                        on: {
                          "initial-package-selection": function($event) {
                            return _vm.updatePackage($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentStep === 1
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "8", lg: "7" } },
                    [
                      _c("subscription-personalisation", {
                        attrs: {
                          subscriptionPackage: _vm.personalisedPackage,
                          stripeSubscriptionPlans: _vm.stripeSubscriptionPlans,
                          onOldPricePlan: _vm.onOldPricePlan
                        },
                        on: {
                          "update:subscriptionPackage": function($event) {
                            _vm.personalisedPackage = $event
                          },
                          "update:subscription-package": function($event) {
                            _vm.personalisedPackage = $event
                          },
                          "personalise-package-selected": function($event) {
                            return _vm.updatePackage($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentStep === 2
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "8", lg: "7" } },
                    [
                      _c("payment", {
                        attrs: { plan: _vm.personalisedPackage },
                        on: {
                          success: function($event) {
                            return _vm.completeOrder($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }