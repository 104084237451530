<template>
  <v-container fluid>
    <v-row class="mb-8" no-gutters align="center" justify="center">
      <v-tabs v-model="tabNumber" fixed-tabs centered>
        <v-tab class="text-h5" ripple :disabled="disableProTab">{{
          $t("account.subscription.menu.tabs.pro")
        }}</v-tab>
        <v-tab class="text-h5" ripple>{{
          $t("account.subscription.menu.tabs.pro+")
        }}</v-tab>
      </v-tabs>
    </v-row>
    <v-row class="mb-4" align="end" justify="space-between">
      <v-col cols="12" class="text-h5 vitrueGrey--text">{{
        $t("account.subscription.personalisation.title")
      }}</v-col>
      <v-col
        cols="12"
        lg="12"
        :class="$vuetify.breakpoint.mdAndDown ? 'mt-4' : ''"
        ><v-slider
          class="mt-4 align-center"
          v-model="userCount"
          min="10"
          max="5000"
          color="primary"
          track-color="disabled"
          thumb-color="primary"
          thumb-label="always"
          hide-details
        >
          <template v-slot:prepend>
            <v-text-field
              class="rounded-0 countInput mr-12"
              outlined
              :label="$t('textFields.employeeNumber')"
              v-model="userCount"
              type="number"
              :hint="userCountHint"
              persistent-hint
              :style="textInputMinWidth"
            />
          </template> </v-slider
      ></v-col>
    </v-row>
    <v-row class="mb-8" align="center" justify="end">
      <v-col cols="12" md="7" lg="6">
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="couponID"
              class="rounded-0"
              :placeholder="$t('textFields.discountLabel')"
              :label="$t('textFields.discountLabel')"
              outlined
              :error-messages="
                couponError
                  ? $t('account.subscription.personalisation.invalidCoupon')
                  : null
              "
              :success-messages="
                couponActive
                  ? $t('account.subscription.personalisation.validCoupon')
                  : ''
              "
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="text-body-1 vitrueGrey--text otherPriceText"
          ><v-col cols="4" md="7">{{
            $t("account.subscription.personalisation.subtotalLabel")
          }}</v-col>
          <v-col cols="5">£{{ priceForUsers.toFixed(2) }}</v-col>
          <v-col cols="4" md="7" v-if="this.couponActive">{{
            $t("account.subscription.personalisation.discountLabel")
          }}</v-col>
          <v-col cols="5" v-if="this.couponActive"
            >- £{{ discountValue.toFixed(2) }}</v-col
          >
          <v-col cols="4" md="7">{{
            $t("account.subscription.personalisation.vatLabel")
          }}</v-col>
          <v-col cols="5">£{{ taxToAdd.toFixed(2) }}</v-col></v-row
        >

        <v-divider class="my-1" />
        <v-row no-gutters class="primary--text totalPriceText"
          ><v-col cols="4" md="7">{{
            $t("account.subscription.personalisation.totalLabel")
          }}</v-col>
          <v-col cols="5">£{{ totalPrice.toFixed(2) }}</v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" no-gutters
      ><v-col cols="10" lg="4"
        ><v-btn
          rounded
          :disabled="userCount === 0"
          color="secondary"
          width="100%"
          class="text-h5"
          @click="checkOut()"
          >{{ $t("buttons.checkout") }}
        </v-btn></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
import { getCouponFromId } from "@/customApi";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tabNumber: 0,
      taxRate: 20,
      couponID: this.subscriptionPackage.coupon
        ? this.subscriptionPackage.coupon.id
        : "",
      couponActive: this.subscriptionPackage.coupon ? true : false,
      couponError: false,
      userCount: this.subscriptionPackage.userCount ?? 0,
      coupon: this.subscriptionPackage.coupon,
      subscriptionName: this.subscriptionPackage.subscriptionName
    };
  },
  props: {
    subscriptionPackage: Object,
    stripeSubscriptionPlans: Array,
    onOldPricePlan: Boolean
  },
  mounted() {
    this.couponActive = this.coupon ? true : false;
    this.tabNumber = this.stripeSubscriptionPlans
      .map(e => {
        return e.subscriptionName;
      })
      .indexOf(this.subscriptionPackage.subscriptionName);
  },
  watch: {
    tabNumber: {
      handler: function () {
        this.subscriptionName =
          this.stripeSubscriptionPlans[this.tabNumber].subscriptionName;
      }
    },
    couponID(newValue) {
      this.coupon = null;
      this.couponActive = false;
      this.couponError = false;
      if (newValue === "") {
        return;
      }

      getCouponFromId(this.teamMemberId, newValue)
        .then(response => {
          this.coupon = response;
          this.couponActive = !!response;
          this.couponError = !response;
        })
        .catch(err => {
          this.couponError = true;
        })
        .finally(() => {
          this.coupon = { ...this.coupon };
        });
    }
  },
  computed: {
    ...mapGetters(["teamMemberId", "userAccessTier"]),
    priceForUsers() {
      return this.stripeSubscriptionPlans[this.tabNumber].cost * this.userCount;
    },
    userCountHint() {
      return this.$t("account.subscription.personalisation.userCount", {
        0: this.stripeSubscriptionPlans[this.tabNumber].cost,
        1: this.userCount
      });
    },
    discountValue() {
      var amountOff = 0;
      if (!this.coupon) {
        return amountOff.toFixed(2);
      }

      if (this.coupon.amount_off) {
        amountOff = this.coupon.amount_off / 100;
      } else if (this.coupon.percent_off) {
        amountOff = (this.priceForUsers / 100) * this.coupon.percent_off;
      }

      return amountOff;
    },
    discountedPrice() {
      let priceToReturn = this.priceForUsers - this.discountValue;
      return priceToReturn < 0 ? 0 : priceToReturn;
    },
    taxToAdd() {
      return (this.taxRate / 100) * this.discountedPrice;
    },
    totalPrice() {
      return this.discountedPrice + this.taxToAdd;
    },
    textInputMinWidth() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return "width: 250px";
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        return "width: 150px";
      }
      return "width: 100px";
    },
    disableProTab() {
      return this.userAccessTier === "Pro" && !this.onOldPricePlan;
    }
  },
  methods: {
    checkOut() {
      let personalisedProduct = this.stripeSubscriptionPlans[this.tabNumber];
      personalisedProduct.userCount = this.userCount;
      personalisedProduct.totalCalculatedPrice = this.totalPrice;
      personalisedProduct.coupon = this.coupon;

      this.$emit("personalise-package-selected", personalisedProduct);
    }
  }
};
</script>

<style scoped>
.countInput input[type="number"] {
  -moz-appearance: textfield;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

.totalPriceText {
  font-size: 20px;
  font-weight: 500;
  font-family: "Roboto";
}
</style>
