<template>
  <div>
    <div class="py-0">
      <v-row justify="center" no-gutters>
        <p class="text-h5 text-center mx-5">
          {{ $t("account.subscription.menu.title") }}
        </p>
      </v-row>
    </div>
    <v-row justify="space-around" align="stretch" class="px-4">
      <v-col
        v-for="plan in subscriptionPlans"
        :key="plan.subscriptionName"
        :cols="cardsColumnSize"
      >
        <v-card class="fill-height">
          <v-row
            class="text-center"
            justify="center"
            align="center"
            :style="'min-height: ' + planRowHeight"
          >
            <v-col cols="12" v-if="subscriptionsAvailable">
              <p
                class="text-h5 primary--text pa-0"
                :style="
                  $vuetify.breakpoint.name === 'lg' ? 'min-height: 60px' : ''
                "
              >
                {{ $t(plan.subscriptionDisplayedName) }}
              </p>
              <p class="text-h3 pa-0">
                {{ plan.subscriptionName != "Enterprise" ? "£" : ""
                }}{{ getPlanPrice(plan) }}
              </p>
              <v-col
                cols="12"
                class="text-subtitle-1 grey--text text-center pa-0"
              >
                <p>
                  <span v-if="plan.subscriptionName !== 'Enterprise'">
                    {{ $t("account.subscription.menu.rate") }}</span
                  >
                  <br />
                  {{
                    $t("account.subscription.menu.minimumUsers", {
                      "0": getMinimumUsers(plan.subscriptionName)
                    })
                  }}
                </p>
              </v-col>
              <v-tooltip
                bottom
                nudge-top="20"
                :disabled="!isSubscriptionDisabled(plan.subscriptionName)"
                color="transparent"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="py-4"
                    style="cursor:default;"
                  >
                    <v-btn
                      color="secondary"
                      depressed
                      width="80%"
                      :disabled="isSubscriptionDisabled(plan.subscriptionName)"
                      @click="choseSubscriptionPlan(plan)"
                      >{{
                        plan.subscriptionName === "Enterprise"
                          ? $t("buttons.contactSales")
                          : $t("buttons.getSignedUp")
                      }}</v-btn
                    >
                  </div>
                </template>
                <vitrue-tooltip
                  :message="getSignUpTooltipMessage(plan.subscriptionName)"
                />
              </v-tooltip>
            </v-col>
            <v-progress-circular
              v-else
              indeterminate
              size="100"
              color="secondary"
            />
          </v-row>
          <hr class="mb-4" />
          <div class="text-center mb-4 text-body-1 mx-4 px-12">
            <p class="font-weight-bold">
              {{ $t(plan.subscriptionSubtitle) }}
            </p>
            <v-row
              v-for="item in plan.subscriptionIncludesList"
              :key="item"
              class="py-1 grey--text text--darken-2 text-wrap"
              justify="center"
              align="center"
              style="min-height: 40px"
              :class="$t(item).includes('Everything') ? 'font-weight-bold' : ''"
              >{{ $t(item) }}</v-row
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="start" no-gutters>
      <v-btn
        class="mb-0 ml-4"
        color="primary"
        outlined
        @click="cancelDialog = true"
        >{{ $t("buttons.cancelSubscription") }}</v-btn
      >
    </v-row>
    <v-dialog v-if="cancelDialog" v-model="cancelDialog" width="50%">
      <v-card>
        <v-btn class="closeButton" icon @click="cancelDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-h5 text-center">
          {{ $t("account.subscription.menu.cancelSubscriptionTitle") }}
        </v-card-title>
        <v-card-text>
          <p
            class="text-center"
            v-html="$t('account.subscription.menu.cancelSubscriptionInfo')"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SubscriptionPlans from "@/assets/json/account/SubscriptionPlans";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    VitrueTooltip
  },
  data() {
    return {
      subscriptionPlans: SubscriptionPlans,
      cancelDialog: false,
      chosenSubscriptionPlan: null
    };
  },
  props: { stripeSubscriptionPlans: Array, onOldPricePlan: Boolean },
  methods: {
    choseSubscriptionPlan(plan) {
      if (plan.subscriptionName === "Enterprise") {
        var win = window.open(
          "https://share.hsforms.com/1iClwDmZPTsq9UqHnOqEVtQ3kp4s",
          "_blank"
        );
        win.focus();
        return;
      }

      this.chosenSubscriptionPlan = this.stripeSubscriptionPlans.find(
        x => x.subscriptionName === plan.subscriptionName
      );

      this.$emit("initial-package-selection", this.chosenSubscriptionPlan);
    },
    isSubscriptionDisabled(name) {
      if (this.onOldPricePlan) {
        return false;
      }
      return this.getPlanIndex(name) <= this.getPlanIndex(this.userAccessTier);
    },
    getSignUpTooltipMessage(name) {
      return this.getPlanIndex(name) < this.getPlanIndex(this.userAccessTier)
        ? this.$t("tooltips.downgradeSubscription", { "0": name })
        : this.$t("tooltips.downgradeSubscriptionSamePlan", { "0": name });
    },
    getPlanIndex(name) {
      let subscriptionNames = SubscriptionPlans.map(x => x.subscriptionName);
      return subscriptionNames.indexOf(name);
    },
    getPlanPrice(plan) {
      if (plan.subscriptionName === "Enterprise") {
        return this.$t("account.subscription.menu.enterpriseTitle");
      }
      return this.stripeSubscriptionPlans.find(
        x => x.subscriptionName === plan.subscriptionName
      ).cost;
    },
    getMinimumUsers(plan) {
      return plan === "Enterprise" ? 500 : 10;
    }
  },
  computed: {
    ...mapGetters(["userAccessTier"]),
    cardsColumnSize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "10";
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return "6";
      }
      return "4";
    },
    subscriptionsAvailable() {
      return (
        !!this.stripeSubscriptionPlans &&
        this.stripeSubscriptionPlans.length > 0
      );
    },
    planRowHeight() {
      return this.$vuetify.breakpoint.name === "xl" ? "280px" : "340px";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.customTitle {
  font-size: 34px;
}
.v-card__title {
  justify-content: center;
}
.subscriptionHeader {
  font-size: 34px;
  color: black;
  font-family: $heading-font-family;
}
.subscriptionHeadline {
  font-size: 24px;
  color: black;
  font-family: $heading-font-family;
}
.priceDetails {
  font-size: 10px;
  font-weight: 300;
  font-family: "Roboto";
  line-height: 12px;
  letter-spacing: 0.4px;
  color: #505050;
  margin-top: 8px;
  height: 25px;
}
.Plans {
  min-height: 250px;
  height: 100%;
}
.divider {
  background: var(--v-disabled-base);
  margin-top: 5px;
  margin-bottom: 8px;
}
.listItem {
  color: #505050;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.4px;
}
.tierColor {
  color: #63a8ff;
}
</style>
