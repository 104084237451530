var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "py-0" },
        [
          _c("v-row", { attrs: { justify: "center", "no-gutters": "" } }, [
            _c("p", { staticClass: "text-h5 text-center mx-5" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("account.subscription.menu.title")) + " "
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "px-4",
          attrs: { justify: "space-around", align: "stretch" }
        },
        _vm._l(_vm.subscriptionPlans, function(plan) {
          return _c(
            "v-col",
            {
              key: plan.subscriptionName,
              attrs: { cols: _vm.cardsColumnSize }
            },
            [
              _c(
                "v-card",
                { staticClass: "fill-height" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "text-center",
                      style: "min-height: " + _vm.planRowHeight,
                      attrs: { justify: "center", align: "center" }
                    },
                    [
                      _vm.subscriptionsAvailable
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "text-h5 primary--text pa-0",
                                  style:
                                    _vm.$vuetify.breakpoint.name === "lg"
                                      ? "min-height: 60px"
                                      : ""
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(plan.subscriptionDisplayedName)
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c("p", { staticClass: "text-h3 pa-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      plan.subscriptionName != "Enterprise"
                                        ? "£"
                                        : ""
                                    ) +
                                    _vm._s(_vm.getPlanPrice(plan)) +
                                    " "
                                )
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-subtitle-1 grey--text text-center pa-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("p", [
                                    plan.subscriptionName !== "Enterprise"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "account.subscription.menu.rate"
                                                )
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "account.subscription.menu.minimumUsers",
                                            {
                                              "0": _vm.getMinimumUsers(
                                                plan.subscriptionName
                                              )
                                            }
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    "nudge-top": "20",
                                    disabled: !_vm.isSubscriptionDisabled(
                                      plan.subscriptionName
                                    ),
                                    color: "transparent"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "py-4",
                                                    staticStyle: {
                                                      cursor: "default"
                                                    }
                                                  },
                                                  "div",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "secondary",
                                                      depressed: "",
                                                      width: "80%",
                                                      disabled: _vm.isSubscriptionDisabled(
                                                        plan.subscriptionName
                                                      )
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.choseSubscriptionPlan(
                                                          plan
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        plan.subscriptionName ===
                                                          "Enterprise"
                                                          ? _vm.$t(
                                                              "buttons.contactSales"
                                                            )
                                                          : _vm.$t(
                                                              "buttons.getSignedUp"
                                                            )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("vitrue-tooltip", {
                                    attrs: {
                                      message: _vm.getSignUpTooltipMessage(
                                        plan.subscriptionName
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              size: "100",
                              color: "secondary"
                            }
                          })
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "mb-4" }),
                  _c(
                    "div",
                    { staticClass: "text-center mb-4 text-body-1 mx-4 px-12" },
                    [
                      _c("p", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t(plan.subscriptionSubtitle)) + " "
                        )
                      ]),
                      _vm._l(plan.subscriptionIncludesList, function(item) {
                        return _c(
                          "v-row",
                          {
                            key: item,
                            staticClass:
                              "py-1 grey--text text--darken-2 text-wrap",
                            class: _vm.$t(item).includes("Everything")
                              ? "font-weight-bold"
                              : "",
                            staticStyle: { "min-height": "40px" },
                            attrs: { justify: "center", align: "center" }
                          },
                          [_vm._v(_vm._s(_vm.$t(item)))]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "start", "no-gutters": "" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mb-0 ml-4",
              attrs: { color: "primary", outlined: "" },
              on: {
                click: function($event) {
                  _vm.cancelDialog = true
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("buttons.cancelSubscription")))]
          )
        ],
        1
      ),
      _vm.cancelDialog
        ? _c(
            "v-dialog",
            {
              attrs: { width: "50%" },
              model: {
                value: _vm.cancelDialog,
                callback: function($$v) {
                  _vm.cancelDialog = $$v
                },
                expression: "cancelDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "closeButton",
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.cancelDialog = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-card-title", { staticClass: "text-h5 text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "account.subscription.menu.cancelSubscriptionTitle"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("v-card-text", [
                    _c("p", {
                      staticClass: "text-center",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "account.subscription.menu.cancelSubscriptionInfo"
                          )
                        )
                      }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }