var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        {
          staticClass: "mb-8",
          attrs: { "no-gutters": "", align: "center", justify: "center" }
        },
        [
          _c(
            "v-tabs",
            {
              attrs: { "fixed-tabs": "", centered: "" },
              model: {
                value: _vm.tabNumber,
                callback: function($$v) {
                  _vm.tabNumber = $$v
                },
                expression: "tabNumber"
              }
            },
            [
              _c(
                "v-tab",
                {
                  staticClass: "text-h5",
                  attrs: { ripple: "", disabled: _vm.disableProTab }
                },
                [_vm._v(_vm._s(_vm.$t("account.subscription.menu.tabs.pro")))]
              ),
              _c("v-tab", { staticClass: "text-h5", attrs: { ripple: "" } }, [
                _vm._v(_vm._s(_vm.$t("account.subscription.menu.tabs.pro+")))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "mb-4",
          attrs: { align: "end", justify: "space-between" }
        },
        [
          _c(
            "v-col",
            { staticClass: "text-h5 vitrueGrey--text", attrs: { cols: "12" } },
            [
              _vm._v(
                _vm._s(_vm.$t("account.subscription.personalisation.title"))
              )
            ]
          ),
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.mdAndDown ? "mt-4" : "",
              attrs: { cols: "12", lg: "12" }
            },
            [
              _c("v-slider", {
                staticClass: "mt-4 align-center",
                attrs: {
                  min: "10",
                  max: "5000",
                  color: "primary",
                  "track-color": "disabled",
                  "thumb-color": "primary",
                  "thumb-label": "always",
                  "hide-details": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function() {
                      return [
                        _c("v-text-field", {
                          staticClass: "rounded-0 countInput mr-12",
                          style: _vm.textInputMinWidth,
                          attrs: {
                            outlined: "",
                            label: _vm.$t("textFields.employeeNumber"),
                            type: "number",
                            hint: _vm.userCountHint,
                            "persistent-hint": ""
                          },
                          model: {
                            value: _vm.userCount,
                            callback: function($$v) {
                              _vm.userCount = $$v
                            },
                            expression: "userCount"
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.userCount,
                  callback: function($$v) {
                    _vm.userCount = $$v
                  },
                  expression: "userCount"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mb-8", attrs: { align: "center", justify: "end" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "7", lg: "6" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        staticClass: "rounded-0",
                        attrs: {
                          placeholder: _vm.$t("textFields.discountLabel"),
                          label: _vm.$t("textFields.discountLabel"),
                          outlined: "",
                          "error-messages": _vm.couponError
                            ? _vm.$t(
                                "account.subscription.personalisation.invalidCoupon"
                              )
                            : null,
                          "success-messages": _vm.couponActive
                            ? _vm.$t(
                                "account.subscription.personalisation.validCoupon"
                              )
                            : ""
                        },
                        model: {
                          value: _vm.couponID,
                          callback: function($$v) {
                            _vm.couponID = $$v
                          },
                          expression: "couponID"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "text-body-1 vitrueGrey--text otherPriceText",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c("v-col", { attrs: { cols: "4", md: "7" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "account.subscription.personalisation.subtotalLabel"
                        )
                      )
                    )
                  ]),
                  _c("v-col", { attrs: { cols: "5" } }, [
                    _vm._v("£" + _vm._s(_vm.priceForUsers.toFixed(2)))
                  ]),
                  this.couponActive
                    ? _c("v-col", { attrs: { cols: "4", md: "7" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "account.subscription.personalisation.discountLabel"
                            )
                          )
                        )
                      ])
                    : _vm._e(),
                  this.couponActive
                    ? _c("v-col", { attrs: { cols: "5" } }, [
                        _vm._v("- £" + _vm._s(_vm.discountValue.toFixed(2)))
                      ])
                    : _vm._e(),
                  _c("v-col", { attrs: { cols: "4", md: "7" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("account.subscription.personalisation.vatLabel")
                      )
                    )
                  ]),
                  _c("v-col", { attrs: { cols: "5" } }, [
                    _vm._v("£" + _vm._s(_vm.taxToAdd.toFixed(2)))
                  ])
                ],
                1
              ),
              _c("v-divider", { staticClass: "my-1" }),
              _c(
                "v-row",
                {
                  staticClass: "primary--text totalPriceText",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c("v-col", { attrs: { cols: "4", md: "7" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "account.subscription.personalisation.totalLabel"
                        )
                      )
                    )
                  ]),
                  _c("v-col", { attrs: { cols: "5" } }, [
                    _vm._v("£" + _vm._s(_vm.totalPrice.toFixed(2)))
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "10", lg: "4" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-h5",
                  attrs: {
                    rounded: "",
                    disabled: _vm.userCount === 0,
                    color: "secondary",
                    width: "100%"
                  },
                  on: {
                    click: function($event) {
                      return _vm.checkOut()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("buttons.checkout")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }