<template>
  <v-dialog v-model="value" persistent :width="dialogSize">
    <v-card class="px-4">
      <v-btn
        class="closeButton"
        icon
        @click="$emit('input', false)"
        x-large
        color="black"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="backButton text-body-1" v-if="currentStep !== 0">
        <v-row no-gutters align="center">
          <v-btn icon @click="currentStep -= 1" x-large color="black">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          {{
            currentStep == 1
              ? $t("account.subscription.dialog.checkPricePlan")
              : ""
          }}
        </v-row>
      </div>
      <v-row class="py-6" justify="center" no-gutters style="min-height: 600px">
        <v-col cols="12" v-if="currentStep === 0"
          ><subscription-menu
            @initial-package-selection="updatePackage($event)"
            :stripeSubscriptionPlans="stripeSubscriptionPlans"
            :onOldPricePlan="onOldPricePlan"
          />
        </v-col>
        <v-col v-if="currentStep === 1" cols="12" md="8" lg="7">
          <subscription-personalisation
            :subscriptionPackage.sync="personalisedPackage"
            :stripeSubscriptionPlans="stripeSubscriptionPlans"
            :onOldPricePlan="onOldPricePlan"
            @personalise-package-selected="updatePackage($event)"
          />
        </v-col>
        <v-col v-if="currentStep === 2" cols="12" md="8" lg="7">
          <payment
            :plan="personalisedPackage"
            @success="completeOrder($event)"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import SubscriptionMenu from "./SubscriptionMenu";
import SubscriptionPersonalisation from "./SubscriptionPersonalisation";
import SubscriptionPlans from "@/assets/json/account/SubscriptionPlans";
import { getSubscriptionPlans } from "../../customApi";
import { mapGetters } from "vuex";
import Payment from "./Payment";

export default {
  components: { SubscriptionMenu, SubscriptionPersonalisation, Payment },
  data() {
    return {
      currentStep: 0,
      personalisedPackage: {},
      stripeSubscriptionPlans: []
    };
  },
  props: {
    value: Boolean
  },
  async mounted() {
    await this.getSubscriptionPlansFromApi();
    this.$mixpanel.track("Subscription dialog", {
      page: this.currentStep,
      memberId: this.teamMemberId
    });
  },
  methods: {
    updatePackage(event) {
      this.personalisedPackage = event;
      this.currentStep += 1;
      this.$mixpanel.track("Subscription dialog", {
        page: this.currentStep,
        memberId: this.teamMemberId
      });
    },
    async getSubscriptionPlansFromApi() {
      try {
        let subscriptionPlansFromStripe = await getSubscriptionPlans(
          this.teamMemberId
        );

        this.stripeSubscriptionPlans = SubscriptionPlans.map(plan => {
          let currentStripePlan =
            subscriptionPlansFromStripe[plan.subscriptionName];
          let associatedActivePrice = currentStripePlan.priceList.find(
            x => x.isActive
          );

          // no active price for enterprise, so we only want to return the sub name
          if (plan.subscriptionName === "Enterprise") {
            return {
              subscriptionName: plan.subscriptionName
            };
          }
          return {
            subscriptionName: plan.subscriptionName,
            cost: associatedActivePrice.cost,
            currency: associatedActivePrice.currency,
            priceId: associatedActivePrice.priceId
          };
        });
      } catch (err) {}
    },
    completeOrder(orderType) {
      this.$mixpanel.track("Subscription dialog", {
        paid: true
      });

      this.$emit("success", orderType);
      this.$emit("input", false);
    }
  },
  computed: {
    ...mapGetters([
      "teamMemberId",
      "currentUserTeamAndSubscriptionBasicInformation"
    ]),
    dialogSize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100%";
      }
      return "80%";
    },
    onOldPricePlan() {
      if (
        this.stripeSubscriptionPlans.length === 0 ||
        !this.currentUserTeamAndSubscriptionBasicInformation.priceId ||
        this.currentUserTeamAndSubscriptionBasicInformation.accessTier ===
          "Enterprise"
      ) {
        return false;
      }
      return !this.stripeSubscriptionPlans.find(
        x =>
          x.priceId ===
          this.currentUserTeamAndSubscriptionBasicInformation.priceId
      );
    }
  }
};
</script>

<style scoped>
.closeButton {
  position: absolute;
  right: 0;
  top: 0;
}
.backButton {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
