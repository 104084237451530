<template>
  <v-container fluid style="height: 100%">
    <v-row style="height: 100%" no-gutters justify="center">
      <v-col cols="12" class="text-h5 text-center" align-self="start">
        {{ $t("account.payment.title") }}
      </v-col>
      <v-col
        cols="12"
        lg="8"
        align-self="center"
        class="text-subtitle-1 vitrueGrey--text font-weight-medium data-hj-suppress"
      >
        <p
          class="mb-12"
          style="font-size: 20px"
          v-html="
            $t('account.payment.totalPriceDescription', {
              '0': plan.totalCalculatedPrice.toFixed(2)
            })
          "
        />
        <v-stripe-card
          ref="stripeElement"
          :label="$t('account.payment.label')"
          :hint="$t('account.payment.hint')"
          :hidePostalCode="true"
          outlined
          v-model="cardSource"
          :api-key="stripePublicKey"
        ></v-stripe-card>
      </v-col>

      <v-col cols="12" lg="8" v-if="errorMessage" class="py-0">
        <alert-message :message="errorMessage" type="error"></alert-message>
      </v-col>

      <v-col cols="12" align-self="center">
        <v-row align="center" justify="center"
          ><v-col cols="10" lg="5"
            ><v-btn
              rounded
              color="secondary"
              width="100%"
              class="text-h5"
              :loading="paymentInProgress"
              @click="createPaymentMethodAndCustomer"
              >{{ $t("buttons.complete") }}
            </v-btn></v-col
          ></v-row
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const stripePublicKey = process.env.VUE_APP_STRIPE_PUBLIC_API_KEY;
import { mapGetters } from "vuex";
import {
  upgradeSubscription,
  createCustomerAndSubscription
} from "../../customApi";
import AlertMessage from "./AlertMessage";
let stripe;

export default {
  name: "Payment",
  props: {
    plan: Object
  },
  components: {
    AlertMessage
  },
  data() {
    return {
      cardSource: null,
      stripePublicKey,
      errorMessage: null,
      paymentInProgress: false
    };
  },
  computed: {
    ...mapGetters(["userEmail", "teamMemberId", "userAccessTier"]),
    isUpgrading() {
      return (
        this.userAccessTier != "Free" &&
        this.userAccessTier != "Expired" &&
        this.userAccessTier != ""
      );
    }
  },
  methods: {
    async createPaymentMethodAndCustomer() {
      this.paymentInProgress = true;
      stripe = this.$refs.stripeElement.stripe;
      this.errorMessage = null;
      try {
        let stripeReturn = await stripe.createPaymentMethod(
          "card",
          this.$refs.stripeElement.card,
          {
            billing_details: {
              email: this.userEmail
            }
          }
        );
        if (stripeReturn.error) {
          this.errorMessage =
            stripeReturn.error.message ||
            this.$t("account.payment.stripeError");
        } else {
          let subscription;
          if (this.isUpgrading) {
            subscription = await this.upgradeSubscriptionPlan(
              stripeReturn.paymentMethod.id
            );
          } else {
            subscription = await this.createCustomer(
              stripeReturn.paymentMethod.id
            );
          }
          await this.handleSubscription(subscription);
        }

        this.paymentInProgress = false;
      } catch (err) {
        this.errorMessage = this.$t("genericError");
        this.paymentInProgress = false;
      }
    },
    async upgradeSubscriptionPlan(paymentMethod) {
      return await upgradeSubscription(this.teamMemberId, {
        email: this.userEmail,
        paymentMethod: paymentMethod,
        priceId: this.plan.priceId,
        AlreadyExistingUser: true,
        couponId: this.plan.coupon ? this.plan.coupon.id : "",
        userCount: this.plan.userCount
      });
    },
    async createCustomer(paymentMethod) {
      return await createCustomerAndSubscription(this.teamMemberId, {
        email: this.userEmail,
        paymentMethod: paymentMethod,
        priceId: this.plan.priceId,
        couponId: this.plan.coupon ? this.plan.coupon.id : "",
        userCount: this.plan.userCount
      });
    },
    async handleSubscription(subscription) {
      const payment_intent = subscription.latest_invoice.payment_intent;

      if (payment_intent) {
        const client_secret = payment_intent.client_secret;
        const status = payment_intent.status;
        if (status === "requires_action") {
          let stripeReturn = await stripe.confirmCardPayment(client_secret);
          if (stripeReturn.error) {
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            this.errorMessage =
              stripeReturn.error.message ||
              this.$t("account.payment.stripeError");
            return;
          }
        }
      }
      let successType = this.isUpgrading ? "upgrade" : "newSubscription";
      this.$emit("success", successType);
    }
  }
};
</script>
